<template>
  <div id="vuma-benefit" class="vm-section vm-benefits">
    <v-container class="text-center">
      <h2>How does Vuma benefit me?</h2>
      <v-row>
        <v-col class="col-6 col-md-3">
          <div class="vm-b-img">
            <img src="@/assets/images/home/benefits/benefit-icon-4.svg" alt="You are always in touch">
          </div>
          <h4>You are always <br>in touch</h4>
          <p>Connect with friends and family on-demand, from the comfort of your home</p>
        </v-col>
        <v-col class="col-6 col-md-3">
          <div class="vm-b-img">
            <img src="@/assets/images/home/benefits/benefit-icon-2.svg" alt="Create a smart home">
          </div>
          <h4>Create a <br>smart home</h4>
          <p>Connect devices and products that allow for a smart lifestyle at home</p>
        </v-col>
        <v-col class="col-6 col-md-3">
          <div class="vm-b-img">
            <img src="@/assets/images/home/benefits/benefit-icon-1.svg" alt="Your home and family is secure">
          </div>
          <h4>Your home and <br>family are secure</h4>
          <p>Utilise innovative and smart technology to further keep your family safe</p>
        </v-col>
        <v-col class="col-6 col-md-3">
          <div class="vm-b-img">
            <img src="@/assets/images/home/benefits/benefit-icon-3.svg" alt="Increase the value of your property">
          </div>
          <h4>Increase the value <br>of your property</h4>
          <p>Add value to your home with fast and reliable internet connectivity</p>
        </v-col>
      </v-row>
      <v-btn rounded outlined @click="$emit('scrollTo')" class="benef-btn">
        Check my coverage
      </v-btn>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'HomeBenefits',
}
</script>

<style lang="scss">
.vm-benefits {
  background-color: #EE008C;
  color: #fff;
  padding-top: 5rem;
  padding-bottom: 5rem;
  h2 {
    margin-bottom: 3rem;
  }

  h4 {
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    font-family: "Core Sans R 55", "Roboto", sans-serif !important;
    font-weight: inherit;
  }

  .vm-b-img {
    margin: auto auto 1.5rem;
    width: 150px;
  }

  p {
    line-height: 1.2;
    padding: 0 1rem;
  }

  .theme--light.v-btn {
    color: #fff;
    margin-top: 3rem;

    &:hover {
      color: #ff008e;
      background-color: #fff;
    }
  }
}
</style>