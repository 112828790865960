import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuetify from './plugins/vuetify'
import styles from '@/assets/scss/app.scss'
import InstantSearch from 'vue-instantsearch'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import VueGtag from 'vue-gtag'
import VueGtm from 'vue-gtm'
import Multiselect from 'vue-multiselect'
import $ from 'jquery'
import VueCookies from 'vue-cookies'
import DataDog from './plugins/datadog'

Vue.use(InstantSearch)
Vue.component('multiselect', Multiselect)
window.$ = $

Vue.use(VueAxios, axios)
Vue.use(VueCookies)
Vue.config.productionTip = false

Vue.$cookies.config('1d')

Vue.use(VueMeta)

Vue.use(Vuelidate)

Vue.use(VueGtag, {
  config: { id: 'UA-78507550-1' }
}, router)

Vue.use(VueGtm, {
  id: 'GTM-KF6QDK6'
})

Vue.use(DataDog)

const app = new Vue({
  el: '#app',
  router,
  vuetify,
  styles,
  store,
  axios,
  VueCookies,
  render: h => h(App),
  metaInfo () {
    return {
      title: 'Vuma Fibre | Fibre to the Home | Fast and Reliable Internet',
      meta: [
        { name: 'description', content: 'We build connected lives, communities and societies through fast, reliable and accessible fibre to your home. Choose your Vuma package today.' },
        { property: 'og:title', content: 'Vuma Fibre | Fibre to the Home | Fast and Reliable Internet' },
        { property: 'og:site_name', content: 'Vumatel' },
        { property: 'og:type', content: 'website' }
      ]
    }
  }
})

app.$mount()
