import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VueBodyClass from 'vue-body-class'
import NotFound from '../views/NotFound'
import Maintenance from '../views/Maintenance'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/product/core',
    name: 'VC',
    component: () => import('../views/Products/Core.vue')
  },
  {
    path: '/product/corporate-social-investment',
    name: 'CSI',
    component: () => import('../views/Products/CorporateSocialInvestment.vue')
  },
  {
    path: '/product/business-fibre-product',
    name: 'FTTB',
    component: () => import('../views/Products/BusinessFibreProduct.vue')
  },
  {
    path: '/product/reach',
    name: 'VR',
    component: () => import('../views/Products/Reach.vue')
  },
  {
    path: '/news',
    name: 'VumaNews',
    meta: { bodyClass: 'news-page' },
    component: () => import('../views/News.vue')
  },
  {
    path: '/news/:slug',
    name: 'VumaArticle',
    meta: { bodyClass: 'news-page article-page' },
    component: () => import('../views/NewsArticle.vue')
  },
  {
    path: '/show-my-interest',
    name: 'VumaShowMyInterest',
    meta: { bodyClass: 'show-my-interest' },
    component: () => import('../views/Salesforce/ShowMyInterest.vue')
  },
  {
    path: '/faqs',
    name: 'FAQ',
    meta: { bodyClass: 'faq-page' },
    component: () => import('../views/FAQ.vue')
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/thank-you',
    name: 'ThankYou',
    meta: { bodyClass: 'thank-you-page' },
    component: () => import('../views/ThankYou.vue')
  },
  {
    path: '/smi-thank-you',
    name: 'SmiThankYou',
    meta: { bodyClass: 'thank-you-page' },
    component: () => import('../views/SmiThankYou.vue')
  },
  {
    path: '/network-status',
    name: 'NetworkStatus',
    meta: { bodyClass: 'status-page' },
    component: () => import('../views/NetworkStatus.vue')
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import('../views/Careers.vue')
  },
  {
    path: '/omniscript',
    name: 'OmniScriptiframe',
    component: () => import('../views/OmniScriptiframe.vue')
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance
  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

const vueBodyClass = new VueBodyClass(routes)
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) })

export default router