<template>
  <div id="vuma-csr" class="vm-section vm-crs d-flex align-center">
    <v-container>
      <v-row class="justify-end">
        <v-col class="col-12 col-sm-6 col-md-5">
          <h2>Vuma enables schools</h2>
          <p>
            At Vuma, we believe in creating an imagination nation. One where every child is given the opportunity to
            connect to online educational platforms, has access to information and aspires to create the lives they
            imagine for themselves with unlimited access to the internet.
            <br><br>
            That’s why we have helped connect over 600 schools with free 1 Gbps fibre, giving young minds access to new
            opportunities and the chance to dream of and strive for a brighter future.
          </p>
          <v-btn rounded outlined @click="$emit('scrollTo')">
            Check my coverage
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div class="vm-crs-bg" :style="{ backgroundImage: `url(${require('@/assets/images/home/vuma-crs.jpg')})` }">
      <img src="@/assets/images/home/vuma-crs.jpg" alt="Vuma CSR header">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeCRS',
}
</script>

<style lang="scss">
.vm-crs {
  height: 95vh;

  .vm-crs-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 5;
    background-size: cover;
    background-position: center left;
    background-repeat: no-repeat;

    img {
      transform: translateY(-15%);
      display: none;
    }
  }

  .container {
    z-index: 10;
    position: relative;

    h2 {
      font-size: 64px !important;
      margin-bottom: 3rem;
      line-height: 1.2;
    }

    p {
      margin-bottom: 3rem;
    }

    .v-btn {
      &:before {
        opacity: 0;
      }

      &:hover {
        color: #fff;
        background-color: #ee008c;
        border-color: #ee008c;
      }
    }
  }
}
</style>