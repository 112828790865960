<template>
  <div id="who-is-vuma" class="vm-section vm-about d-flex align-center">
    <v-container class="align-center">
      <v-row>
        <v-col class="col-12 col-md-6">
          <h1>Who is Vuma?</h1>
          <p class="vm-about-intro">
            <strong>
              As we become more reliant on technology and the internet to go about life, while enjoying streaming
              platforms and online content in the comfort of our homes, we at Vuma believe in a world where everyone is
              connected. We truly look forward to helping build a connected nation - it all starts with accessible
              fibre.
            </strong>
            <br><br>
            We’re excited to think that people across South Africa can have access to an abundance of information and
            knowledge that empowers them to move forward.
            <br><br>
            <strong>With us by your side,</strong> let’s build connected lives, communities and societies. Together
            let’s make the move to create a world that encourages us all to reach for the future and connect to more.
          </p>
          <v-btn rounded outlined class="pink-outline" @click="$emit('scrollTo')">
            Check my coverage
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div class="vm-about-img" :style="{ backgroundImage: 'url(' + require('@/assets/images/home-about.jpg') + ')' }">
      <img src="@/assets/images/home-about.jpg" alt="">
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeAbout',
}
</script>

<style lang="scss">
.vm-about {
  background-color: #F2F2F2;
  position: relative;

  h1 {
    font-size: 64px;
    font-family: "Core Sans R 25", "Roboto", sans-serif !important;
    font-weight: inherit;
    margin-bottom: 2rem;
  }

  h2 {
    margin-bottom: 3rem;
  }

  .vm-about-intro {
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 3rem;
    max-width: 640px;
    width: 100%;
  }

  .vm-about-img {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: top right;
    background-repeat: no-repeat;
    z-index: 1;

    img {
      max-height: 100%;
      display: none;
    }
  }

  .container {
    position: relative;
    z-index: 2;
  }
}
</style>