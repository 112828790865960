<template>
  <div id="high-speed">
    <v-card class="vm-tabs">
      <v-tabs
        v-model="tab"
        dark
        grow
        class="d-none"
      >
        <v-tab key="tab-fibre">Fibre to the Home</v-tab>
        <v-tab key="tab-fibre-biz">Fibre to the Business</v-tab>
        <v-tab key="tab-camera">Camera</v-tab>
        <v-tab key="tab-solar">Solar</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" class="vm-home-tabs">
        <v-tab-item key="tab-fibre">
          <div class="vm-section vm-tab-items d-flex align-center">
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-7 col-md-5 col-lg-4">
                  <h2>Fibre to the home</h2>
                  <p class="vm-tab-content">
                    Your future begins at home. Fibre gives you and your family the foundation to access unlimited
                    benefits and opportunities. As we become more reliant on technology and the internet to go about our
                    daily lives, while enjoying access to streaming platforms and online content in the comfort of our
                    homes, fast and reliable fibre can help us change the way we do life and business.
                  </p>
                  <v-btn rounded outlined class="white-outline" @click="$emit('scrollTo')">
                    Check my coverage
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div
              class="vm-tab-bg"
              :style="{ backgroundImage: `url(${require('@/assets/images/home/tabs/fibre-to-home.jpg')})` }"
            >
              <img src="@/assets/images/home/tabs/fibre-to-home.jpg" alt="">
            </div>
          </div>
        </v-tab-item>
        <v-tab-item key="tab-fibre-biz">
          <div class="vm-section vm-tab-items d-flex align-center">
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-7 col-md-5 col-lg-4">
                  <h2>Fibre for business</h2>
                  <p class="vm-tab-content">
                    We know that with smart thinking and brave actions we can bridge these divides. Our fibre makes this
                    possible. By opening up unlimited opportunities, in real time, we know that we can make reliable
                    internet accessible to all.
                  </p>
                  <v-btn href="#" rounded outlined class="white-outline">
                    Check my coverage
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div class="vm-tab-bg"
                 :style="{ backgroundImage: 'url(' + require('@/assets/images/tab-default.jpg') + ')' }">
              <img src="@/assets/images/tab-default.jpg" alt="">
            </div>
          </div>
        </v-tab-item>
        <v-tab-item key="tab-camera">
          <div class="vm-section vm-tab-items d-flex align-center">
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-7 col-md-5 col-lg-4">
                  <h2>Camera</h2>
                  <p class="vm-tab-content">
                    We know that with smart thinking and brave actions we can bridge these divides. Our fibre makes this
                    possible. By opening up unlimited opportunities, in real time, we know that we can make reliable
                    internet accessible to all.
                  </p>
                  <v-btn href="#" rounded outlined class="white-outline">
                    Check my coverage
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div class="vm-tab-bg"
                 :style="{ backgroundImage: 'url(' + require('@/assets/images/tab-default.jpg') + ')' }">
              <img src="@/assets/images/tab-default.jpg" alt="">
            </div>
          </div>
        </v-tab-item>
        <v-tab-item key="tab-solar">
          <div class="vm-section vm-tab-items d-flex align-center">
            <v-container>
              <v-row>
                <v-col class="col-12 col-sm-7 col-md-5 col-lg-4">
                  <h2>Solar</h2>
                  <p class="vm-tab-content">
                    We know that with smart thinking and brave actions we can bridge these divides. Our fibre makes this
                    possible. By opening up unlimited opportunities, in real time, we know that we can make reliable
                    internet accessible to all.
                  </p>
                  <v-btn href="#" rounded outlined class="white-outline">
                    Check my coverage
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
            <div class="vm-tab-bg"
                 :style="{ backgroundImage: 'url(' + require('@/assets/images/tab-default.jpg') + ')' }">
              <img src="@/assets/images/tab-default.jpg" alt="">
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tab: null,
    }
  },
}
</script>


<style lang="scss">
.vm-tabs {
  .theme--dark.v-tabs {
    & > .v-tabs-bar {
      background-color: #EE008C;
      height: 85px;
    }

    .v-tab {
      font-size: 25px;
      font-family: "Core Sans R 35", "Roboto", sans-serif !important;
      text-transform: none;
      /*min-width: 360px;*/
      letter-spacing: 0.1em;
      width: 20%;

      &.v-tab--active {
        font-family: "Core Sans R 55", "Roboto", sans-serif !important;

        &::before {
          opacity: 0.24 !important;
        }

        // &:not(:focus)::before { opacity: 0.24 !important; }
        &:after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: 20px solid #EE008C;
          position: absolute;
          bottom: -20px;
          left: 50%;
        }
      }

      &:hover::before {
        opacity: 0.24;
      }
    }

    .v-tabs-slider-wrapper {
      display: none;
    }
  }
}

.vm-tab-items {
  overflow: hidden;
  height: 850px;
  color: #fff;
  // NEST
  .container {
    position: relative;
    z-index: 3;

    h2 {
      line-height: 1;
      margin-bottom: 2rem;
      font-size: 64px !important;
    }

    p.vm-tab-content {
      margin-bottom: 3rem;
    }
  }

  .vm-tab-bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    // NEST
    img {
      transform: translateY(-10%);
      display: none;
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 5;
    }
  }
}

// MEDIA QUERIES
/* @media screen and (max-width: 767px){
  .vm-tab-items .vm-tab-bg {
      position: relative;
      top: auto;
      right: auto;
      height: auto;
      width: auto;
      background: none;
      background-image: none !important;
      // NEST
      img{
        display: block !important;
        transform: none;
      }
  }
} */


</style>