<template>
  <div class="home">
    <HomeBanner/>
    <HomeCoverage/>
    <HomeAbout @scrollTo="scrollCoverage"/>
    <HomeTabs @scrollTo="scrollCoverage"/>
    <HomeBenefits @scrollTo="scrollCoverage"/>
    <HomeCRS @scrollTo="scrollCoverage"/>
    <VumaCommnunityBanner />
    <HomeFeaturedNews :isPage="false"/>
  </div>
</template>

<script>

import $ from 'jquery'
import HomeBanner from '@/components/Home/Banner'
import HomeCoverage from '@/components/Home/Coverage'
import HomeAbout from '@/components/Home/About'
import HomeTabs from '@/components/Home/Tabs'
import HomeBenefits from '@/components/Home/Benefits'
import HomeCRS from '@/components/Home/CRS'
import HomeFeaturedNews from '@/components/Home/FeaturedNews'
import VumaCommnunityBanner from '@/components/Home/VumaCommnunityBanner'

export default {
  name: 'Home',
  components: {
    HomeBanner,
    HomeCoverage,
    HomeAbout,
    HomeTabs,
    HomeBenefits,
    HomeCRS,
    HomeFeaturedNews,
    VumaCommnunityBanner
  },
  computed: {
    callToAction () {
      return {
        text: 'Get connected',
        href: 'https://get.vumatel.co.za/connected_t20worldcup/',
        target: '_blank'
      }
    }
  },
  methods: {
    scrollCoverage () {
      $('html, body').stop().animate({
        'scrollTop': $('.vm-coverage-wrap').offset().top - 50,
      }, 500, 'swing')
    },
  },
}
</script>
