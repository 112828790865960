<template>
  <div class="vm-section vm-featured-news">
    <v-container class="p-relative">
      <h1 class="text-center" v-if="ispage">Vuma News</h1>
      <h2 class="text-center" v-if="!ispage">Featured News</h2>
      <div v-if="featuredArticles.length">
        <carousel
          :items="3"
          :margin="5"
          :nav="false"
          :dots="false"
          :responsive="{
            0:{items:1},
            475:{items:2},
            767:{items:3}
          }"
          class="vm-fn-articles"
        >
          <template slot="prev">
            <img src="@/assets/images/shop/nav-arrow-left.svg" alt="" class="prev fn-lines">
          </template>
          <div class="news-carousel" v-for="(article, index) in featuredArticles" :key="index">
            <v-card class="news-article" :to="article.url" hover>
              <div class="featured-tag d-flex align-center">
                <img src="@/assets/images/home/news/featured-icon.svg" alt="">
                Featured
              </div>
              <div>
                <div
                  v-if="article.photos !== ''"
                  class="featured-img-cont"
                  :style="{ 'background-image': `url(${article.photos})` }">
                  <img :src="article.photos" v-show="loaded" :alt="article.title" class="d-none"/>
                </div>
                <div
                  v-else
                  class="featured-img-cont"
                  :style="{'background-image': `url(${require('@/assets/images/banner-default.jpg')})`}"
                />
              </div>
              <v-skeleton-loader
                class="mx-auto"
                type="image"
                v-if="loading"
              />
              <div class="vm-fn-date">
                {{ article.date_modified }}
              </div>
              <v-card-title class="fm-fn-title">
                {{ article.title }}
              </v-card-title>
              <a :to="article.url" class="vm-fn-link">> Read more</a>
            </v-card>
          </div>
          <template slot="next">
            <img src="@/assets/images/shop/nav-arrow.svg" alt="" class="next fn-lines">
          </template>
        </carousel>
      </div>
      <div class="text-center" id="read-more-news">
        <v-btn to="/news" rounded outlined>
          Read more
        </v-btn>
      </div>
    </v-container>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
  name: 'HomeFeaturedNews',
  components: { carousel },
  props: ['ispage'],
  data: () => ({
    featuredArticles: [],
    loading: true,
    loaded: false
  }),
  created () {
    let vue = this

    const readyHandler = () => {
      if (document.readyState === 'complete') {
        this.loading = false
        this.loaded = true
        document.removeEventListener('readystatechange', readyHandler)
      }
    }

    document.addEventListener('readystatechange', readyHandler)

    readyHandler()
    let cms_url = process.env.VUE_APP_CMS_HOST + '/featured.json'
    vue.axios.get(cms_url).then(response => {
      vue.featuredArticles = response.data.data
    })
  },
}
</script>

<style lang="scss">
.vm-featured-news {
  background-color: rgba(166, 166, 166, .25); // #A6A6A6; // #F2F2F2;

  h2, h1 {
    color: #595959;
    margin-bottom: 3rem;
  }

  h1 {
    font-size: 68px;
    font-family: "Core Sans R 25", "Roboto", sans-serif !important;
    font-weight: inherit;
  }

  .vm-fn-articles {
    margin-bottom: 3rem;
    position: relative;
  }

  .news-article {
    padding-bottom: 30px;
    position: relative;

    .vm-fn-date {
      font-family: "Core Sans R 55", "Roboto", sans-serif !important;
      font-size: 16px;
      color: #EE008C;
      padding: 30px 30px 0;
    }

    .fm-fn-title {
      font-size: 28px;
      padding: 16px 30px;
      line-height: 1.3;
      word-break: break-word;
    }

    .vm-fn-link {
      font-family: "Core Sans R 55", "Roboto", sans-serif !important;
      font-size: 16px;
      color: #EE008C;
      padding: 0 30px;
      display: block;
      text-decoration: none;
      text-transform: capitalize;
    }

    .featured-tag {
      width: 100%;
      position: absolute;
      top: 1.5rem;
      left: 1rem;
      z-index: 9;
      text-transform: uppercase;
      font-family: "Core Sans R 55", "Roboto", sans-serif !important;
      font-size: 16px;
      color: #fff;

      img {
        width: 40px;
        display: inline-block;
        margin-right: 0.5rem;
        margin-top: -10px;
      }
    }
    .featured-img-cont {
      width: 100%;
      height: 320px;
      background-color: #eee;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .v-image__image {
      transition: 0.5s all;
    }

    &:hover .v-image__image {
      transform: scale(1.05);
    }

    &:not(.v-sheet--tile):not(.v-card--shaped) {
      border-radius: 4px;
      overflow: hidden;
    }
  }

  .theme--light.v-btn {
    color: #EE008C;
    text-transform: none;
    min-width: 200px;

    &:before {
      opacity: 0;
    }

    &:hover {
      color: #fff;
      background-color: #ee008c;
      border-color: #ee008c;
    }
  }

  // CAROUSEL SLIDER
  .next, .prev {
    position: absolute;
    top: 50%;
    z-index: 999;
    font-size: 30px;
    color: #EE008C;
    cursor: pointer;
    width: 30px;

    &.av-lines {
      transform: none;
      top: 60%;
    }
  }

  .prev {
    left: -50px;
  }

  .next {
    right: -50px;
  }

  .owl-carousel .owl-stage-outer {
    padding-bottom: 1rem !important;
  }

  .owl-carousel .owl-item {
    padding: 0.5rem;
  }

}

@media screen and (max-width: 1490px) {
  .vm-featured-news .container {
    max-width: 1280px !important;
  }
}

@media screen and (max-width: 1390px) {
  .vm-featured-news .container {
    max-width: 1180px !important;
  }
}

@media screen and (max-width: 1290px) {
  .vm-featured-news .container {
    max-width: 1080px !important;
  }
}

@media screen and (max-width: 1180px) {
  .vm-featured-news .container {
    max-width: 960px !important;
  }
}

@media screen and (max-width: 1080px) {
  .vm-featured-news .next {
    right: -15px;
  }
  .vm-featured-news .prev {
    left: -15px;
  }
}

@media screen and (max-width: 991px) {
  .vm-featured-news .next, .vm-featured-news .prev {
    top: auto;
    width: 20px;
    bottom: 0;
  }
  .vm-featured-news .next {
    left: 50%;
    right: auto;
  }
  .vm-featured-news .prev {
    left: 46%;
  }
}

@media screen and (max-width: 645px) {
  .vm-featured-news .theme--light.v-btn {
    padding: 0.75rem 1rem !important;
    font-size: 18px !important;
  }
  .vm-featured-news .news-article .featured-img-cont {
    height: 190px;
  }
  .vm-featured-news .next, .vm-featured-news .prev {
    bottom: -15px;
  }
}

@media screen and (max-width: 590px) {
  .vm-featured-news .news-article .featured-tag {
    top: 0.75rem;
    left: 0.5rem;
    font-size: 14px;
  }
  .vm-featured-news .news-article .featured-tag img {
    width: 30px;
    margin-top: 0;
  }
}
</style>