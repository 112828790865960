<template functional>
  <div class="cookie-notification">
    <div class="text">
      Vumatel is committed to protecting the personal information you may share with us on our website.<br/>
      For more information on our data protection practices and our use of cookies to improve your experience on our website, see our
      <a
        class="url"
        href="https://cdn.vumatel.co.za/files/vumatel-privacy-policy.pdf"
        target="_blank"
        title="Privacy Policy"
      >
        Privacy Policy
      </a>
    </div>
    <v-btn
      rounded
      dark
      class="mr-3 move-to"
      @click="props.onAcceptClick"
    >
      I accept
    </v-btn>
  </div>
</template>

<script>
export default {
  functional: true,
  name: 'CookieNotification',
  props: {
    onAcceptClick: {
      type: Function,
      required: true
    }
  }
}
</script>

<style scoped>
.cookie-notification {
  padding: 1rem 5%;
  background-color: #17183b;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  color: #fff;
}
.text {
  font-size: 1rem;
  padding: 0 0 1rem 0;
  text-align: center;
}
.url {
  color: #ff008e;
  text-decoration: none;
}
.url:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 768px) {
  .cookie-notification {
    flex-direction: row;
    justify-content: space-between;
  }
  .text {
    text-align: left;
    font-size: 1.25rem;
    padding: 0 1rem 0 0;
  }
}
</style>