<template>
  <div class="vm-coverage-wrap home-coverage" id="vm-coverage-wrap">
    <div class="vm-section vm-coverage">
      <v-container class="text-center">
        <div>
          <h2>Check if you are covered.</h2>
          <div class="vm-coverage-input">
            <v-text-field
              ref="autocomplete"
              v-model="coverage_address"
              label="Enter your address"
              placeholder=""
              class="mb-5"
              required
            />
            <v-btn href="#" rounded dark @click="searchAddress($event)" :loading="loading" :disabled="gAutoComplete">
              Check my coverage
            </v-btn>
          </div>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import gmapsInit from '@/utils/gmaps'
import { VUMA_KEY_SUBURB, VUMA_KEY_POSTAL_CODE } from '@/store/constants-vuma-key.js'
import { generateUuid } from '@/helpers'

export default {
  name: 'HomeCoverage',
  data: () => ({
    suburb: '',
    street_name: '',
    street_number: '',
    country: '',
    province: '',
    city: '',
    postal_code: '',
    place_id: '',
    coverage_address: '',
    searched: false,
    lat: '',
    long: '',
    pos: '',
    loading: false,
    markersArray: [],
    autoCompleteReset: '',
    dialog: false,
    gAutoComplete: true,
    autocomplete: undefined,
    autocompleteOptions: {
      componentRestrictions: { country: 'za' },
      fields: [
        'formatted_address',
        'geometry',
        'place_id',
        'address_components'
      ],
      sessionToken: generateUuid()
    }
  }),
  async mounted () {
    try {
      await gmapsInit()
      this.initializeAutocomplete()
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    searchAddress (event) {
      event.preventDefault()
      let vue = this
      vue.loading = true

      vue.address_components.forEach(function (item, index) {
        if (vue.address_components[index].types[0] === 'postal_code') {
          vue.postal_code = vue.address_components[index].long_name
        }
      })

      if (vue.address_components[0].types[0] === 'street_number') {
        vue.suburb = vue.address_components[2].long_name
        vue.street_name = vue.address_components[1].long_name
        vue.street_number = vue.address_components[0].long_name
        vue.country = vue.address_components[6].long_name
        vue.province = vue.address_components[5].long_name
        vue.city = vue.address_components[3].long_name
      }
      if (vue.address_components[0].types[0] === 'sublocality_level_1') {
        vue.suburb = vue.address_components[0].long_name
        vue.street_name = null
        vue.street_number = null
        vue.country = vue.address_components[4].long_name
        vue.province = vue.address_components[3].long_name
        vue.city = vue.address_components[1].long_name
      }
      if (vue.address_components[0].types[0] === 'locality') {
        vue.suburb = vue.address_components[0].long_name
        vue.street_name = null
        vue.street_number = null
        vue.country = vue.address_components[3].long_name
        vue.province = vue.address_components[2].long_name
        vue.city = vue.address_components[1].long_name
      }

      this.$cookies.set('smi_city', vue.city)
      this.$cookies.set('smi_suburb', vue.suburb)
      this.$cookies.set('smi_postal_code', vue.postal_code)

      if (vue.street_number != null && vue.street_name != null) {
        this.$cookies.set('check_address', 'hide')
        this.$cookies.set('smi_street_name', vue.street_name)
        this.$cookies.set('smi_street_number', vue.street_number)
      } else {
        this.$cookies.set('check_address', 'show')
      }

      this.$store.dispatch('getCoverage', ({
        latitude: vue.lat,
        longitude: vue.long,
        address: vue.pos
      })).then(coverage => {
        this.$cookies.set('lat', vue.lat).set('long', vue.long).set('user_address', vue.pos).set('product_type', coverage.product)

        // TODO: VUMAKEY will likely have its own page
        if (coverage.product === 'VUMAREACH' || coverage.product === 'VUMAKEY') {
          vue.$router.push({ name: 'VR' })
        } else {
          vue.$router.push({ name: 'VC' })
          if (!coverage?.covered
            || (coverage.status !== 'LIVE' && coverage.status !== 'BUILD')
            || ((coverage.product !== 'VUMACORE') && (coverage.product !== 'VUMAREACH'))
          ) {
            this.$store.commit('setIsDialog', true)
          }
        }

        vue.scrollTop()

        vue.loading = false
      })
    },
    scrollTop () {
      var target = '.vm-coverage-wrap'
      var $target = $(target)

      $('html, body').stop().animate({
        'scrollTop': $('.vm-coverage-wrap').offset().top - 70,
      }, 500, 'swing')
    },
    initializeAutocomplete () {
      const input = this.$refs.autocomplete.$refs.input
      this.autocomplete = new google.maps.places.Autocomplete(input, this.autocompleteOptions)
      this.autocomplete.addListener('place_changed', this.handlePlaceChanged)
    },
    handlePlaceChanged () {
      const place = this.autocomplete.getPlace()
      this.autocompleteOptions = {
        ...this.autocompleteOptions,
        sessionToken: generateUuid()
      }
      this.autocomplete.setOptions(this.autocompleteOptions)
      this.place_id = place.place_id
      this.$cookies.set('smi_place_id', this.place_id)
      this.lat = place.geometry.location.lat()
      this.long = place.geometry.location.lng()
      this.pos = place.formatted_address
      this.address_components = place.address_components
      this.gAutoComplete = false
    },
    isAlexLocation(suburb, postalCode, pos, coverage) {
      return (suburb.toUpperCase() === VUMA_KEY_SUBURB)
        || ((pos.toUpperCase().includes(VUMA_KEY_SUBURB) || coverage.toUpperCase().includes(VUMA_KEY_SUBURB))
          && postalCode === VUMA_KEY_POSTAL_CODE)
    }
  },
}
</script>

<style lang="scss">

#content {
  font-style: bold;
  font-size: 20px;
  padding: 15px 20px;
  text-align: center;
  .g-condition {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .g-address {
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  #order {
    padding: 0.75rem 46px;
    border-radius: 31px;
    color: #fff;
    background-color: #ff008e;
    border: 0;
    font-weight: 600;
    display: inline-block;
    text-decoration: none;
  }
}

button.gm-ui-hover-effect {
  top: 0 !important;
  right: 0 !important;
}

.vm-coverage {
  // NEST
  // .v-btn { text-transform: capitalize; }
  .v-input .v-label {
    font-size: 32px;
    height: 45px;
    line-height: 33px !important;

    &.v-label--active {
      top: 0;
      font-size: 22px;
    }
  }

  .v-text-field input {
    line-height: 45px;
    max-height: 45px;
    font-size: 20px;
  }

  .vm-coverage-input {
    max-width: 650px;
    width: 100%;
    margin: 1rem auto 0;
    // NEST
    .theme--light.v-input input {
      font-size: 20px;
    }
  }
}

.coverage-results {
  position: relative;
  text-align: center;
  // NEST
  h2 {
    background: #FF008E;
    color: #fff;
    padding: 2rem;
  }
}

#googlemap.showing-map {
  position: relative;
  width: 100%;
  height: 500px;
}

.theme--light.v-input input, .theme--light.v-input textarea {
  color: rgba(0, 0, 0, 0.87);
  font-size: 20px;
}

.home-coverage.vm-coverage-wrap {
  height: auto;
  overflow: auto;
}
</style>
