import { datadogRum } from '@datadog/browser-rum'

export default {
  install: (app, options) => {
    datadogRum.init({
      applicationId: process.env.VUE_APP_DATADOG_APPLICATION_ID,
      clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
      site: process.env.VUE_APP_DATADOG_SITE,
      env: process.env.VUE_APP_DATADOG_ENV,
      service: process.env.VUE_APP_DATADOG_SERVICE,
      sampleRate: parseInt(process.env.VUE_APP_DATADOG_SAMPLE_RATE),
      premiumSampleRate: parseInt(process.env.VUE_APP_DATADOG_PREMIUM_SAMPLE_RATE),
      trackInteractions: process.env.VUE_APP_DATADOG_TRACK_INTERATIONS,
      defaultPrivacyLevel: process.env.VUE_APP_DATADOG_DEFAULT_PRIVACY_LEVEL
    })

    datadogRum.startSessionReplayRecording()
  }
}
