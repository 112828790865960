import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import PRODUCT_TYPES from '../constants/productTypes'
import STATUSES from '../constants/statuses'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    covered: null,
    status: null,
    latitude: null,
    longitude: null,
    address: null,
    product: null,
    hasError: false,
    isDialog: false,
    isLoading: false
  },
  mutations: {
    SET_LOCATION (state, payload) {
      state.covered = payload.covered
      state.status = payload.status
      state.latitude = payload.latitude
      state.longitude = payload.longitude
      state.address = payload.address
      state.product = payload.product
      state.willNavigateToShop = payload.willNavigateToShop
    },
    updateLocation (state, payload) {
      state.location = {
        ...state.location,
        ...payload
      }
    },
    UPDATE_LOCATION (state, payload) {
      state = { ...payload }
      state.what = { ...payload }
    },
    setHasError (state, value) {
      state.hasError = value
    },
    setIsDialog (state, value) {
      state.isDialog = value
    },
    setIsLoading (state, value) {
      state.isLoading = value
    }
  },
  actions: {
    async getCoverage ({ state, commit }, { latitude, longitude, ...locationDetails }) {
      const locationUrl = process.env.VUE_APP_COVERAGE_CHECK_URL
      const response = await axios.get(locationUrl, {
        params: {
          lat: latitude,
          long: longitude
        },
        headers: {
          'x-api-key': process.env.VUE_APP_COVERAGE_CHECK_TOKEN,
          'Content-Type': 'application/json'
        }
      })

      let selectedArea = {
        latitude,
        longitude,
        covered: false,
        status: null,
        product: null,
        willNavigateToShop: false,
        ...locationDetails
      }

      // Areas ordered closest to furthest
      const coveredAreas = Array.isArray(response.data) ? response.data : []

      // Select the first closest, viable area
      for (const area of coveredAreas) {
        const {
          covered,
          status,
          product_type: product,
          rsf_enabled: willNavigateToShop
        } = area

        // Skip over non-viable areas
        if (!STATUSES[status] || !PRODUCT_TYPES[product]) continue

        selectedArea = {
          latitude,
          longitude,
          covered,
          status,
          product,
          willNavigateToShop,
          ...locationDetails
        }
        break
      }

      commit('SET_LOCATION', selectedArea)

      // Need to use session storage as VueJS doesn't update in jQuery onclick functions
      sessionStorage.setItem('product', selectedArea.product)
      sessionStorage.setItem('willNavigateToShop', selectedArea.willNavigateToShop)

      return state
    }
  },
  modules: {
  },
  getters: {
    hasError: state => {
      return state.hasError
    },
    isDialog: state => {
      return state.isDialog
    },
    isLoading: state => {
      return state.isLoading
    }
  }
})