<template>
  <v-app>
    <header class="vuma-header">
      <v-container>
        <div class="d-flex align-center justify-space-between">
          <a href="/" class="vm-logo">
            <img src="./assets/images/vuma-logo.svg" alt="Vuma Logo" class="vuma-logo-white">
            <img src="./assets/images/vuma-logo-pink.svg" alt="Vuma Logo" class="vuma-logo-pink">
          </a>
          <div class="d-flex top-nav">
            <v-btn to="/" rounded dark class="mr-3 move-to" id="top-coverage" data-section="vm-coverage-wrap">
              Check my coverage
            </v-btn>
            <v-btn href="#" text @click="showMenu($event)">
              <v-icon size="30">mdi-menu</v-icon>
            </v-btn>
          </div>
        </div>
      </v-container>
      <div class="vuma-menu">
        <div class="burger-meun-wrapper">
          <div>
            <v-btn class="close-nav white--text outlined" icon @click="hideMenu($event)">
              <img src="@/assets/images/menu-close.svg" alt="">
            </v-btn>
            <v-list-item-group color="primary" style="margin-bottom: 0; padding-bottom: 0">
              <v-list-item class="move-to" to="/" data-section="vm-coverage-wrap">Check your coverage</v-list-item>
              <v-list-item class="move-to" to="/" data-section="high-speed">Our offerings</v-list-item>
              <v-list-item class="move-to" to="/" data-section="who-is-vuma">About us</v-list-item>
              <v-list-item to="/careers" data-section="careers">Careers</v-list-item>
              <v-list-item to="/product/corporate-social-investment">Corporate social responsibility</v-list-item>
              <v-list-item to="/product/business-fibre-product">Fibre to the business </v-list-item>
              <v-list-item to="/news">News</v-list-item>
              <v-list-item to="/network-status">Network Status</v-list-item>
              <v-list-item to="/contact-us">Contact us</v-list-item>
              <v-list-item to="/faqs">FAQs</v-list-item>
            </v-list-item-group>
            <v-list-item-group color="primary">
              <v-list-item @click="speedTestExpanded = !speedTestExpanded">
                <span>Speed Test</span>
                <span :class="{'speed-test-arrow-dropdown-rotated': speedTestExpanded }" class="speed-test-arrow-dropdown">></span>
              </v-list-item>
              <div class="speed-test-menu-wrapper" :class="{'speed-test-menu-opened': speedTestExpanded}">
                <v-list-item class="speed-test-item"><a href="https://speed1-sa1.vx.se" target="_blank">Johannesburg</a></v-list-item>
                <v-list-item class="speed-test-item"><a href="https://speed2-sa1.vx.se" target="_blank">Durban</a></v-list-item>
                <v-list-item class="speed-test-item"><a href="https://speed3-sa1.vx.se" target="_blank">Cape Town</a></v-list-item>
              </div>
            </v-list-item-group>
          </div>
        </div>
      </div>
      <div class="menu-overlay" @click="hideMenu($event)"/>
    </header>
    <v-main class="relative">
      <router-view/>
    </v-main>
    <v-btn
      v-if="isCookieAccepted"
      href="#"
      fab
      dark
      id="back-to-top"
      @click="scrollTop"
      class="back-to-top"
    >
      <img src="@/assets/images/menu-close.svg" alt="" style="transform: rotateZ(-90deg);">
    </v-btn>
    <Footer/>
    <CookieNotification
      v-if="!isCookieAccepted"
      :on-accept-click="updateCookie"
    />
  </v-app>
</template>

<script>
import '@fortawesome/fontawesome-free/css/all.css'
import Footer from './components/Footer'
import CookieNotification from './components/CookieNotification'
import $ from 'jquery'

const hasAcceptedCookie = 'hasAcceptedCookie'

export default {
  name: 'App',
  components: {
    Footer,
    CookieNotification
  },
  data(){
    return{
      backToTopClass: 'back-to-top',
      isCookieAccepted: this.$cookies.get(hasAcceptedCookie) || false,
      speedTestExpanded: false
    }
  },
  mounted () {
    const self = this
    $('html').css('overflow', 'auto')
    $(window).scroll(function () {
      let scroll = $(window).scrollTop()

      if (scroll >= 45) {
        $('header').addClass('fixed-top')
      } else {
        $('header').removeClass('fixed-top')
      }

      // SHOW SCROLL TO TOP BTN
      if (scroll > 450) {
        $('#back-to-top').addClass('slide-in')
      } else {
        $('#back-to-top').removeClass('slide-in')
      }
    })

    $('.move-to').on('click', function (e) {
      e.preventDefault()

      let target = '#' + $(this).data('section')
      let $target = $(target)

      $('html, body').stop().animate({
        'scrollTop': $target.offset().top - 70,
      }, 900, 'swing')

      self.hideMenu(e)
    })
  },
  methods: {
    updateCookie () {
      const data = {
        has_accepted: true
      };
      this.axios.post(process.env.VUE_APP_PANGEA_BASE_URL + '/orders/cookies_consent_reqests/', data)
      .then(() => {
        this.isCookieAccepted = true
        const date = new Date()
        date.setDate(date.getDate() + 30)
        this.$cookies.set(hasAcceptedCookie, true, date)
        
      })
      .catch(error => {
        console.error('Error:', error)
      });

    },
    showMenu (event) {
      event.preventDefault()
      $('.vuma-menu, .menu-overlay').addClass('menu-show')
      $('#back-to-top').addClass('d-none')
      $('html').css('overflow', 'hidden')
    },
    hideMenu (event) {
      event.preventDefault()
      $('.vuma-menu, .menu-overlay').removeClass('menu-show')
      $('#back-to-top').removeClass('d-none')
      $('html').css('overflow', 'auto')
      this.speedTestExpanded = false
    },
    scrollTop () {
      let target = 'html, body'
      let $target = $(target)

      $('html, body').stop().animate({
        'scrollTop': $target.offset().top,
      }, 500, 'swing')
    },
    scrollCoverage () {
      let $target = $('.vm-coverage-wrap')

      $('html, body').stop().animate({
        'scrollTop': $target.offset().top - 50,
      }, 500, 'swing')
    },
  },
}
</script>

<style lang="scss" scoped>
.burger-meun-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  overflow-y: auto;
}

.speed-test-item {
  font-size: 1rem !important;
  padding: 0.25rem 0rem !important;
}

.speed-test-item a {
  font-size: 1rem !important;
  padding: 0.25rem 1rem !important;
  color: white;
  text-decoration: none;
}

.speed-test-item a:hover {
  text-decoration: underline;
}

.speed-test-arrow-dropdown {
  transform: rotate(90deg) scaleY(1.4);
  display: inline-block;
  margin-left: 1rem;
  font-weight: 100;
}

.speed-test-arrow-dropdown-rotated {
  transform: rotate(270deg) translateY(6px) scaleY(1.4);
}

.speed-test-menu-wrapper {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transform: translateY(-20%);
  transition: all 0.25s ease-out;
}

.speed-test-menu-opened {
  transform: translateY(0%);
  height: 6rem;
  opacity: 1;
}

@media only screen and (max-width: 520px) {
  .btn-vcc {
    right: 1.5rem;
    bottom: 8px;
    max-width: 10rem;
  }
  .vcc-icon {
    height: 7rem;
  }
}
</style>